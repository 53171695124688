import { Helmet } from 'react-helmet-async';

//Images
import wordImage from './assets/images/what-we-do.svg'
import logo from './assets/images/logo.png'
import defaultImage from './assets/images/default-background.svg'
import largeImage from './assets/images/large-screen-background.svg'
import smallImage from './assets/images/mobile-screen-background.svg'
import bigVid from './assets/images/big-vid-placeholder.jpg'
import circleVid from './assets/images/circle-vid-placeholder.jpg'
import domeVid from './assets/images/dome-vid-placeholder.jpg'
import pointer from './assets/images/pointer.svg'
import linkedin from './assets/images/linkedin.svg'
import instagram from './assets/images/instagram.svg'
import tiktok from './assets/images/tiktok.svg'
import plane from './assets/images/paper-plane.svg'
import ScrollingText from './components/ScrollingText';
import BodyText from './components/BodyText';
import Video from './components/Video';
import GlassCursorEffect from './components/GlassCursorEffect';

function App() {
    return (
        <div className="App">
            <Helmet>
                <title>Sprung Social</title>
                <meta name="description" content="Creative digital solutions for brands." />
            </Helmet>
            <div className="top-bar">
                <div className="container">
                    <img src={logo} alt="Logo" />
                </div>
            </div>
            <GlassCursorEffect />
            <div className="main">
                <div className="default-bg" style={{backgroundImage: `url(${defaultImage})`}}></div>
                <div className="large-bg" style={{backgroundImage: `url(${largeImage})`}}></div>
                <div className="small-bg" style={{backgroundImage: `url(${smallImage})`}}></div>
                <div className="container">
                    <div className="wrapper">
                        <div className="videos desktop-only">
                            {/* Big Video */}
                            <div className="big-video">
                                <div className="video magnify" >
                                    <Video videoId="7sLJcxjvKNg" title="Big Video" />
                                </div>
                            </div>

                            {/* Small Videos */}
                            <div className="small-videos">
                                {/* Circle Video */}
                                <div className="circle-video">
                                    <div className="video">
                                        <Video videoId="NgnadP2kdxU" title="Circle Video" />
                                    </div>
                                </div>

                                {/* Dome Video */}
                                <div className="dome-video">
                                    <div className="video">
                                        <Video videoId="T6Be3qrkpXs" title="Dome Video" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Additional Content */}
                        <div className="content">
                            <div className="text-wrapper">
                                <div className="trapezoid-shape">
                                    <img src={wordImage} alt="What we do" />
                                </div>
                                <div className="text">
                                    <div className="circle-video mobile-only">
                                        <div className="video">
                                            <Video videoId="NgnadP2kdxU" title="Circle Video" />
                                        </div>
                                    </div>
                                    <p className='desktop-only'>
                                        <BodyText />
                                    </p>
                                </div>
                            </div>
                            <p className='mobile-only'>
                                <BodyText />
                            </p>
                            <p>We love people, and we love data, but most of all, we <br /> love storytelling.</p>

                            <div id="scroller" className='mobile-only'>
                                <ScrollingText />
                            </div>

                            <div className="videos mobile-only">
                                <div className="big-video">
                                    <div className="video">
                                        <Video videoId="7sLJcxjvKNg" title="Big Video" />
                                    </div>
                                </div>
                                <div className="dome-video">
                                    <div className="video">
                                        <Video videoId="T6Be3qrkpXs" title="Dome Video" />
                                    </div>
                                </div>
                            </div>

                            <div className="links">
                                <a href="mailto:hello@sprungsocial.com" className="plane mobile-only" target="_blank" rel="noreferrer">
                                    <i style={{ backgroundImage: `url(${plane})` }}></i>
                                </a>
                                <a href="mailto:hello@sprungsocial.com" className="mail-link mobile-only" target="_blank" rel="noreferrer">get in<em>_____</em><span>TOUCH</span> <i>hello@sprungsocial.com</i> <img src={pointer} alt="pointer" className="pointer" /></a>
                                <a href="https://www.instagram.com/sprung.social/" className="instagram" target="_blank" rel="noreferrer">
                                    <i style={{ backgroundImage: `url(${instagram})` }}></i>
                                </a>
                                <a href="https://www.linkedin.com/company/sprungsocial/?originalSubdomain=za" className="linkedin" target="_blank" rel="noreferrer">
                                    <i style={{ backgroundImage: `url(${linkedin})` }}></i>
                                </a>
                                <a href="https://www.tiktok.com/@little.biggies" className="tiktok" target="_blank" rel="noreferrer">
                                    <i style={{ backgroundImage: `url(${tiktok})` }}></i>
                                </a>
                                <a href="mailto:hello@sprungsocial.com" className="plane desktop-only" target="_blank" rel="noreferrer">
                                    <i style={{ backgroundImage: `url(${plane})` }}></i>
                                </a>
                                <a href="mailto:hello@sprungsocial.com" className="mail-link desktop-only" target="_blank" rel="noreferrer">get in<em>_____</em><span>TOUCH</span> <i>hello@sprungsocial.com</i> <img src={pointer} alt="pointer" className="pointer" /></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="scroller" className='desktop-only'>
                    <ScrollingText />
                </div>
            </div>
        </div>
    );
}

export default App;

